import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useParams, useHistory } from 'react-router-dom'
import County from '../County'
import maps from '../../config/maps'
import ElectionContext from '../../context/ElectionContext'
import useData from '../../hooks/useData'

const CountyIndex = ({ start }) => {
  let { slug } = useParams()
  const history = useHistory()
  const context = useContext(ElectionContext)
  const map = maps.countySelect
  const mapData = useData(map.topoJson, false)
    ?.objects?.mnCounties?.geometries?.map((item) => item.properties)
    ?.sort((a, b) => (a[map.properName] > b[map.properName] ? 1 : -1))

  if (slug) {
    return <County countyCode={slug} start={start} />
  } else {
    return (
      <div className="County">
        <Helmet>
          <title>MPR News | Live Results by County</title>
          <meta
            name="twitter:title"
            content="LIVE Election Results from MPR News | Local County"
          />
          <meta
            property="og:title"
            content="LIVE Election Results from MPR News | Local County"
          />
        </Helmet>
        <div className="section_header">
          <h2 className="hdg hdg-2">Local Results by County</h2>
        </div>
        <select
          onChange={(event) => history.push(event.target.value)}
          onBlur={(event) => history.push(event.target.value)}
        >
          <option value={`/${context.date}/county`}>Select a County</option>
          {mapData?.map((county) => (
            <option
              value={`/${context.date}/county/${county[map.keyAttribute]}`}
            >
              {county[map.properName]}
            </option>
          ))}
        </select>
        {/* <CountyMap
          width={450}
          height={350}
          center={[-94, 46.5]}
          projection={projection}
          topoJson={map.topoJson}
          keyAttribute={map.keyAttribute}
          properName={map.properName}
          elementClass="countyIndex"
        /> */}
      </div>
    )
  }
}

export default CountyIndex

CountyIndex.propTypes = {
  start: PropTypes.string,
}
