import React, { useState } from 'react'
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from 'react-simple-maps'
import { PARTY_COLORS } from '../../config'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import {
  MN_HOUSE_DISTRICT_PATTERN,
  MN_SENATE_DISTRICT_PATTERN,
  US_HOUSE_DISTRICT_PATTERN,
} from '../../util'
import { useHistory, useLocation } from 'react-router-dom'

const BasicMap = ({
  width,
  height,
  projection,
  center,
  topoJson,
  keyAttribute,
  properName,
  mapName,
  mapTag,
  data,
  previousElection,
  uncontestedData,
  elementClass,
}) => {
  const [tooltip, setTooltip] = useState({})
  const [position, setPosition] = useState({ zoom: 1 })
  const location = useLocation()
  const history = useHistory()

  function handleZoomIn() {
    if (position.zoom >= 4) return
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }))
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }))
  }

  function handleMoveEnd(position) {
    setPosition(position)
  }

  function handleMouseEnter(evt) {
    if (!data) return
    const label = evt.target.attributes['data-geolabel'].value
    const key = evt.target.attributes['data-geokey']
      ? evt.target.attributes['data-geokey'].value
      : 'label'

    let zoneData = data[key.replace(/^0/, '')]
    let oldZoneData = previousElection
      ? previousElection[key.replace(/^0/, '')]
      : null
    if (!zoneData) {
      setTooltip({
        label: label,
      })
      return
    }
    // This check is here in case a race is uncontested
    const obj = {
      label: label,
      winningParty: zoneData.winningParty,
      won: zoneData.won,
    }
    if (zoneData.reportingPct > 0) obj.reportingPct = zoneData.reportingPct
    if (zoneData.winningVotePct > 0)
      obj.winningVotePct = zoneData.winningVotePct
    if (oldZoneData?.winningParty) obj.previousWinner = oldZoneData.winningParty
    setTooltip(obj)

    let paths = document.querySelectorAll('.rsm-geography')
    let pathArray = Array.from(paths)
    pathArray.sort((a, b) => {
      if (
        a.getAttribute('data-geokey') === evt.target.getAttribute('data-geokey')
      ) {
        return -1
      } else {
        return 1
      }
    })
    evt.target.setAttribute('stroke-width', 1.5)
  }

  function handleMouseLeave(evt) {
    evt.target.setAttribute('stroke-width', 0.5)
  }

  function mapType(mapTag) {
    let map
    if (mapTag === 'mnHouse') {
      map = 'house'
    } else if (mapTag === 'mnSenate') {
      map = 'senate'
    } else if (mapTag === 'usHouse') {
      map = 'house'
    }
    return map
  }

  function handleFocus(evt) {
    evt.target.setAttribute('stroke-width', 1.5)

    const key = evt.target.attributes['data-geokey']
      ? evt.target.attributes['data-geokey'].value
      : 'label'

    let url = location.pathname.split('/')
    let slug = url[url.length - 1]
    let isSlugMnHouseDistrict = MN_HOUSE_DISTRICT_PATTERN.test(slug)
    let isSlugMnSenateDistrict = MN_SENATE_DISTRICT_PATTERN.test(slug)
    let isSlugUsHouseDistrict = US_HOUSE_DISTRICT_PATTERN.test(slug)

    if (
      isSlugMnHouseDistrict ||
      isSlugMnSenateDistrict ||
      isSlugUsHouseDistrict
    ) {
      url.pop()
    } else if (slug === 'senate') {
      url.pop()
      url.push('mn')
    }

    url.pop()
    url = url.join('/')
    history.push(`${url}/${mapType(mapTag)}/${key}`)
  }

  function handleBlur(evt) {
    evt.target.setAttribute('stroke-width', 0.5)
  }

  function chooseFillColor(data, label) {
    if (data === undefined) {
      return '#00000033'
    }

    if (data.winningParty === null) {
      let thisRace = uncontestedData?.find((d) => d.district === label)
      let winnerByDefault = thisRace?.candidates[0]

      if (winnerByDefault === undefined || thisRace === undefined) {
        return '#00000033'
      }
      return PARTY_COLORS[winnerByDefault.party.toLowerCase()]
    }

    return PARTY_COLORS[data.winningParty.toLowerCase()]
  }

  function chooseOpacity(data) {
    const undecided = 0.3
    const decided = 1
    if (data === undefined || data.winningParty === null) {
      return undecided
    }
    return data.won ? decided : undecided
  }

  return (
    <div className={`map ${elementClass}`}>
      <ReactTooltip className="tooltip" id={`tooltip-${mapName}`}>
        <h3 className="tooltip_title">{tooltip.label}</h3>
        <table>
          <tbody>
            {tooltip.winningParty && (
              <tr>
                <td className="tooltip_label">Winning Party</td>
                <td className="tooltip_data">{tooltip.winningParty}</td>
              </tr>
            )}
            {tooltip.winningVotePct && (
              <tr>
                <td className="tooltip_label">Winning Vote Percent</td>
                <td className="tooltip_data">
                  {Math.round(tooltip.winningVotePct * 100)}%
                </td>
              </tr>
            )}
            {tooltip.reportingPct && (
              <tr>
                <td className="tooltip_label">Reporting Percent</td>
                <td className="tooltip_data">
                  {Math.round(tooltip.reportingPct * 100)}%
                </td>
              </tr>
            )}
            {tooltip.previousWinner && (
              <tr>
                <td className="tooltip_label">Previous Winning Party</td>
                <td className="tooltip_data">{tooltip.previousWinner}</td>
              </tr>
            )}
          </tbody>
        </table>
      </ReactTooltip>
      <ComposableMap projection={projection} width={width} height={height}>
        <ZoomableGroup
          zoom={position.zoom}
          center={center}
          onMoveEnd={handleMoveEnd}
        >
          <Geographies
            geography={topoJson}
            data-tip
            data-for={`tooltip-${mapName}`}
          >
            {({ geographies }) => {
              return geographies.map((geo) => {
                let key = geo.properties[keyAttribute].replace(/^0/, '')
                let label = geo.properties[properName]
                  ? geo.properties[properName]
                  : `${properName} ${key}`
                const thisData = data && data[key]

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={chooseFillColor(thisData, label)}
                    opacity={chooseOpacity(thisData)}
                    stroke="#03324E"
                    strokeWidth={0.5}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    data-geokey={key}
                    data-geolabel={label}
                  />
                )
              })
            }}
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <div className="controls">
        <button onClick={handleZoomIn} aria-label="Zoom In">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="3"
          >
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </button>
        <button onClick={handleZoomOut} aria-label="Zoom Out">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="3"
          >
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </button>
      </div>
    </div>
  )
}

BasicMap.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
  projection: PropTypes.func.isRequired,
  center: PropTypes.array.isRequired,
  topoJson: PropTypes.string.isRequired,
  keyAttribute: PropTypes.string,
  properName: PropTypes.string,
  data: PropTypes.object,
  previousElection: PropTypes.object,
  mapName: PropTypes.string,
  mapTag: PropTypes.string,
}

export default BasicMap
