import React from 'react'
import PropTypes from 'prop-types'
import generateDataPoints from './generateDataPoints'
import calculateColumnSize from './calculateColumnSize'
import { PARTY_COLORS } from '../../config'

// TODO: Make this more "math-y". Don't, for example, hard-set the offsets and column sizes. Attempt to calculate them and keep it very precise, and based on the size of each svg

const SQUARE_DIMENSIONS = 20
const SQUARE_PADDING = 5
const SQUARE_TOTAL = SQUARE_DIMENSIONS + SQUARE_PADDING // each square will take up 25 pixels relative to the svg's viewbox
const INVISIBLE_OPACITY = 0.0
const APPROX_CONTAINER_WIDTH = 500 // an approximated width of the container the graphic will appear in
const LINE_WIDTH_FACTOR = 2.5 // roughly correlates to pixedl width on screen
const DASH_LENGTH_FACTOR = 2.5 // a multiple of the line width
const DASH_GAP_FACTOR = 1 // a multiple of the line width

const BoPChart = (props) => {
  const addPlaceholderData = (inputData, chartAttrs) => {
    let remainder = inputData.length % chartAttrs.colSize
    let remainderFill = remainder !== 0 ? chartAttrs.colSize - remainder : 0

    let placeholderSquares = new Array(remainderFill).fill({
      party: 'none',
      opacity: INVISIBLE_OPACITY,
    })

    // find the point in the middle of the chart where these placeholders should be inserted
    let midPoint = Math.floor(inputData.length / 2) + chartAttrs.midPointOffset
    let increment = 0

    let returnData = inputData

    placeholderSquares.forEach((square) => {
      returnData.splice(midPoint + increment, 0, square)
      increment++
    })

    return returnData
  }

  const startingData = generateDataPoints(props)
  const chartAttrs = calculateColumnSize(startingData.length)
  const width = (startingData.length / chartAttrs.colSize) * SQUARE_TOTAL
  const data = addPlaceholderData(startingData, chartAttrs)
  const hasIndependents = Boolean(
    props.other || props.other_holdovers || props.other_leaning
  )

  const createChart = (width, data, attrs) => {
    let xPos = 0
    let strokeWidth = (width / APPROX_CONTAINER_WIDTH) * LINE_WIDTH_FACTOR
    let dashArray = `${strokeWidth * DASH_LENGTH_FACTOR} ${
      strokeWidth * DASH_GAP_FACTOR
    }`

    let actualWidth = attrs.isOdd ? width + 11 : width
    let strokeXPos = attrs.isOdd ? actualWidth / 2 : actualWidth / 2 - 2

    return (
      <svg
        id="left"
        data-testid="bop"
        viewBox={`0 0 ${actualWidth} ${attrs.colSize * SQUARE_TOTAL}`}
      >
        {data.map((d, i) => {
          if (i % attrs.colSize === 0) {
            xPos += SQUARE_TOTAL
          }

          let partyColor = d.party !== 'none' ? PARTY_COLORS[d.party] : '#fff'
          return (
            <rect
              width={SQUARE_DIMENSIONS}
              height={SQUARE_DIMENSIONS}
              x={xPos - SQUARE_TOTAL}
              y={(i % attrs.colSize) * SQUARE_TOTAL + 2}
              fill={partyColor}
              opacity={d.opacity}
              key={i}
            />
          )
        })}
        <line
          stroke="gray"
          strokeWidth={strokeWidth}
          strokeDasharray={dashArray}
          x1={strokeXPos}
          y1={0}
          x2={strokeXPos}
          y2={width}
        />
      </svg>
    )
  }

  return (
    <div className="bopChart">
      {props.label && (
        <div className="bopChart_title">
          <h3 className="hdg hdg-3">{props.label}</h3>
        </div>
      )}
      {props.left === 0 && props.right === 0 ? (
        <p>Polls close at 8 pm on November 5th.</p>
      ) : (
        <>
          <div className="bopChart_container">
            <div>{createChart(width, data, chartAttrs)}</div>
          </div>
          <ol className="bopChart_candidates">
            <li className="candidate">
              <div className="candidate_tag dem">
                <span className="candidate_tag_party">DEM</span>
              </div>
              <div className="candidate_stats">
                <h4 className="hdg hdg-3">Democrats</h4>
                <div className="candidate_votes">
                  {props.left_caucus
                    ? props.left + props.left_caucus
                    : props.left}{' '}
                  Seats
                </div>
              </div>
            </li>
            <li className="candidate">
              <div className="candidate_tag gop">
                <span className="candidate_tag_party">GOP</span>
              </div>
              <div className="candidate_stats">
                <h4 className="hdg hdg-3">Republicans</h4>
                <div className="candidate_votes">
                  {props.right_caucus
                    ? props.right + props.right_caucus
                    : props.right}{' '}
                  Seats
                </div>
              </div>
            </li>
          </ol>
          <div className="bopChart_legend">
            <div className="legend">
              <div className="legend_won">
                {hasIndependents && <span className="independent"></span>}
                Won
              </div>
              <div className="legend_trending">
                {hasIndependents && <span className="independent"></span>}
                Trending
              </div>
              <div className="legend_noResults">No Results</div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

BoPChart.propTypes = {
  type: PropTypes.string,
  total: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  left_leaning: PropTypes.number,
  left_holdovers: PropTypes.number,
  left_caucus: PropTypes.number,
  right_caucus: PropTypes.number,
  right: PropTypes.number.isRequired,
  right_leaning: PropTypes.number,
  right_holdovers: PropTypes.number,
  other: PropTypes.number.isRequired,
  other_leaning: PropTypes.number,
  other_holdovers: PropTypes.number,
  label: PropTypes.string,
}

export default BoPChart
