import React from 'react'
import IconCheckmark from './IconCheckmark'
import IconClose from './IconClose'
import IconExternal from './IconExternal'
import IconGavel from './IconGavel'
import IconInfographic from './IconInfographic'
import IconLive from './IconLive'
import IconLocationCheck from './IconLocationCheck'
import IconMenu from './IconMenu'
import IconMn from './IconMn'
import IconMnCounties from './IconMnCounties'
import IconMnHouse from './IconMnHouse'
import IconMnSeal from './IconMnSeal'
import IconMnSenate from './IconMnSenate'
import IconMnUsHouseDist from './IconMnUsHouseDist'
import IconPointer from './IconPointer'
import IconStar from './IconStar'
import IconUs from './IconUs'
import IconWhitehouse from './IconWhitehouse'
import IconBallotBox from './IconBallotBox'
import IconBlankCalendar from './IconBlankCalendar'
import IconTwitter from './IconTwitter'
import IconFacebook from './IconFacebook'
import IconChevronRight from './IconChevronRight'
class Svg extends React.Component {
  render() {
    return (
      <svg style={{ display: 'none' }}>
        <IconCheckmark />
        <IconClose />
        <IconExternal />
        <IconGavel />
        <IconInfographic />
        <IconLive />
        <IconLocationCheck />
        <IconMenu />
        <IconMn />
        <IconMnCounties />
        <IconMnHouse />
        <IconMnSeal />
        <IconMnSenate />
        <IconMnUsHouseDist />
        <IconPointer />
        <IconStar />
        <IconUs />
        <IconWhitehouse />
        <IconBallotBox />
        <IconBlankCalendar />
        <IconTwitter />
        <IconFacebook />
        <IconChevronRight />
      </svg>
    )
  }
}

export default Svg
