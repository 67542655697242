// @ TODO
import logo from '../../assets/svg/logo-election2024.svg'

const nav = [
  {
    title: 'Presidential Results',
    href: 'president',
    icon: 'whitehouse',
  },
  {
    title: 'National Results',
    href: 'national',
    icon: 'us',
  },
  {
    title: 'U.S. Senate',
    href: 'us/senate',
    icon: 'mn',
  },
  {
    title: 'U.S. House',
    href: 'us/house',
    icon: 'mn-us-house-dist',
  },
  {
    title: 'Statewide',
    href: 'statewide',
    icon: 'mn',
  },
  {
    title: 'Minnesota Senate',
    href: 'mn/senate',
    icon: 'mn-senate',
  },
  {
    title: 'Minnesota House',
    href: 'mn/house',
    icon: 'mn-house',
  },
  {
    title: 'Judicial',
    href: 'judicial',
    icon: 'gavel',
  },
  {
    title: 'Local Races by County',
    href: 'county',
    icon: 'mn-counties',
  },
  /* {
    title: 'Live Coverage',
    href: 'https://www.mprnews.org/story/2024/11/06/live-election-results-updates-minnesota',
    icon: 'live',
  }, */
  {
    title: 'View Results by Address',
    href: 'https://www.mprnews.org/build-your-ballot',
    icon: 'location-check',
  },
]

const overview = [
  {
    title: 'President',
    href: 'president',
    buttonText: 'Map & Details',
    type: 'PresidentialNational',
  },
  {
    title: 'U.S. Senate and House',
    href: 'national',
    buttonText: 'More Results',
    type: 'BalanceOfPower',
    area: 'U.S.',
    bop: 'bopUs',
    label: 'U.S. Senate',
    headerText:
      'In the Senate, there are 34 seats up in 2024 - including a special election in Nebraska - of which 23 are held by Democrats or Independents.',
  },
  {
    title: 'U.S. Senate',
    href: 'us/senate',
    buttonText: 'Map & Details',
    label: 'usSenate',
    type: 'Race',
  },
  {
    title: 'U.S. House in Minnesota',
    label: 'usHouse',
    href: 'us/house',
    buttonText: 'Map & Details',
    type: 'Race',
  },
  {
    title: 'Minnesota Legislature',
    type: 'BalanceOfPower',
    area: 'MN',
    bop: 'bopMn',
    headerText:
      'All 134 seats in the Minnesota House are up for election, while only one seat in the Minnesota Senate is on the ballot.',
  },
]

const national = []

const priorityCandidates = ['Kamala Harris', 'Donald Trump']

const electionConfig2024 = {
  ap: false,
  slug: 'electionConfig2024',
  date: '2024-11-05',
  nav: nav,
  year: '2024',
  overview: overview,
  national: national,
  logo: logo,
  priorityPresidentialCandidates: priorityCandidates,
}

export default electionConfig2024
