import React, { useState } from 'react'
import { PARTY_CSS_CLASSES } from '../../config'
import { slugify } from '../../util'
import Svg from '../Svg'
import PropTypes from 'prop-types'

const Race = ({
  level,
  candidates,
  source,
  compact,
  name,
  precinctsReportingPct,
}) => {
  const [displayCandidate, setDisplayCandidate] = useState()
  const hasImages = ['usHouse', 'governor', 'attorney-general'].includes(level)

  const sortCandidates = () => {
    return candidates.sort((a, b) => {
      if (a.votecount > b.votecount) {
        return -1
      }
      if (a.votecount < b.votecount) {
        return 1
      }
      return 0
    })
  }

  const buildCandidateNodes = () => {
    return sortCandidates().map((candidate) => {
      const tagClasses = `candidate_tag ${PARTY_CSS_CLASSES[candidate.party]}`
      const pctClasses = `candidate_pct ${
        candidate.winner === true ? 'candidate_pct_win' : ''
      } ${source}`

      let candidateImage
      if (hasImages) {
        candidateImage = (
          <img
            src={`${process.env.PUBLIC_URL}/data/imgs/${slugify(
              candidate.name
            )}.jpg?q=11-05-2024`}
            alt={candidate.name}
            className="candidate_tag_img"
          />
        )
      }

      return (
        <li
          className={`candidate ${compact ? 'candidate-compact' : ''} ${
            displayCandidate === candidate.polnum ? 'active' : ''
          }`}
          key={candidate.polnum ? candidate.polnum : candidate.candidateId}
        >
          {!compact && (
            <div className={tagClasses}>
              {candidateImage}
              <span className="candidate_tag_party">{candidate.party}</span>
            </div>
          )}

          <div className="candidate_stats">
            <h4 className="hdg hdg-3">{candidate.name}</h4>
            <div className={pctClasses}>
              {candidate.winner && (
                <Svg icon="checkmark" origHeight={13} origWidth={17} />
              )}{' '}
              {Math.round(candidate.votepct * 100)}%
            </div>

            <div className="candidate_votes">
              {candidate.votecount.toLocaleString()}
            </div>
          </div>
        </li>
      )
    })
  }

  const buildCandidateBars = () => {
    return sortCandidates().map((candidate) => {
      var raceBarStyle = {
        width: candidate.votepct * 100 + '%',
      }

      var raceBarClass = `graph_segment ${PARTY_CSS_CLASSES[candidate.party]}`

      return (
        <div
          onMouseOver={() => setDisplayCandidate(candidate.polnum)}
          onFocus={() => setDisplayCandidate(candidate.polnum)}
          onMouseOut={() => setDisplayCandidate(undefined)}
          onBlur={() => setDisplayCandidate(undefined)}
          key={candidate.polnum ? candidate.polnum : candidate.candidateId}
          className={`${raceBarClass} ${
            displayCandidate === candidate.polnum ? 'active' : ''
          }`}
          style={raceBarStyle}
        >
          <span className="invisible">
            {`${candidate.name}: ${Math.round(candidate.votepct * 100)}%`}
          </span>
        </div>
      )
    })
  }

  return (
    <div className="race">
      <div className="race_header">
        <div className="split">
          <div className="split_left"></div>
          <div className="split_right">
            {Math.round(precinctsReportingPct * 100)}% Reporting
          </div>
        </div>
      </div>
      <div className="race_graph">
        <div className="graph">{buildCandidateBars()}</div>
      </div>
      <div className="race_body">
        <ol className="race_candidates">{buildCandidateNodes()}</ol>
      </div>
    </div>
  )
}

Race.propTypes = {
  candidates: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  precinctsReportingPct: PropTypes.number.isRequired,
  compact: PropTypes.bool,
  level: PropTypes.string,
  source: PropTypes.string,
}

export default Race
