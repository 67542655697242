import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const IconFacebook = (props) => {
  const classes = classNames({
    icon: true,
    'icon-facebook': true,
    [props.elementClass]: props.elementClass,
  })

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`icon icon-mail ${
        props.elementClass ? props.elementClass : ''
      }`}
    >
      <title>Facebook</title>
      <path d="M24 12.073C24 5.44558 18.6274 0.072998 12 0.072998C5.37258 0.072998 0 5.44558 0 12.073C0 18.0625 4.3882 23.027 10.125 23.9272V15.5417H7.07812V12.073H10.125V9.42925C10.125 6.42175 11.9166 4.7605 14.6576 4.7605C15.9701 4.7605 17.3438 4.99487 17.3438 4.99487V7.948H15.8306C14.34 7.948 13.875 8.87308 13.875 9.823V12.073H17.2031L16.6711 15.5417H13.875V23.9272C19.6118 23.027 24 18.0625 24 12.073Z" />
    </svg>
  )
}

IconFacebook.propTypes = {
  elementClass: PropTypes.string,
}

export default IconFacebook
