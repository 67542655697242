import React, { useContext } from 'react'
import RaceFull from '../RaceFull'
import { CountyMap } from '../Map'
import Loading from '../Loading'
import useData from '../../hooks/useData'
import PropTypes from 'prop-types'
import Error from '../Error'
import { geoMercator } from 'd3-geo'
import maps from '../../config/maps'
import { useHistory } from 'react-router-dom'
import ElectionContext from '../../context/ElectionContext'

const UsSenateResults = ({ url, interval }) => {
  const history = useHistory()
  const context = useContext(ElectionContext)
  const data = useData(url, interval)
  const map = maps.usSenate
  const projection = geoMercator().scale(2000)
  const mapData = useData(map.topoJson, false)
    ?.objects?.mnCounties?.geometries?.map((item) => item.properties)
    ?.sort((a, b) => (a[map.properName] > b[map.properName] ? 1 : -1))

  if (data === undefined) {
    return <Loading />
  } else if (typeof data === 'string') {
    return <Error err={data} />
  }

  const buildRaceNodes = () => {
    const keys = Object.keys(data[`${map.dataLabel}CountyMap`])

    return data.usSenate.map((race, i) => {
      return (
        <div className="grid" key={keys[i]}>
          <div className="section section-md">
            <div className="grid-col grid_col-half">
              <CountyMap
                width={450}
                height={350}
                center={[-94, 46.5]}
                projection={projection}
                topoJson={map.topoJson}
                keyAttribute={map.keyAttribute}
                properName={map.properName}
                data={data[`${map.dataLabel}CountyMap`][keys[i]]}
                mapName={keys[i]}
              />
            </div>
            <div className="grid-col grid_col-half">
              <RaceFull {...race} key={race.district} />
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <div>
      <div className="grid grid-fill">
        <div className="section_header">
          <h2 className="hdg hdg-2">U.S. Senate Results in Minnesota</h2>
        </div>
        <select
          onChange={(event) => history.push(event.target.value + '/ussenate')}
          onBlur={(event) => history.push(event.target.value + '/ussenate')}
        >
          <option value={`/${context.date}/us/senate`}>Select a County</option>
          {mapData?.map((county) => (
            <option
              value={`/${context.date}/county/${county[map.keyAttribute]}`}
            >
              {county[map.properName]}
            </option>
          ))}
        </select>
        {buildRaceNodes()}
      </div>
    </div>
  )
}

UsSenateResults.propTypes = {
  url: PropTypes.string.isRequired,
  interval: PropTypes.number,
}

export default UsSenateResults
