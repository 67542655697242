import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const IconRSS = (props) => {
  const classes = classNames({
    icon: true,
    'icon-rss': true,
    [props.elementClass]: props.elementClass,
  })

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={`icon icon-mail ${
        props.elementClass ? props.elementClass : ''
      }`}
    >
      <title>RSS</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.995718 0V4.58043C11.6904 4.58749 20.3828 13.2954 20.3927 24H24.9858C24.983 17.3848 22.2935 11.3904 17.9473 7.04139C13.6011 2.69379 7.60955 0.0028222 0.995718 0ZM0.988662 8.16181V12.7437C3.97878 12.7451 6.81086 13.9205 8.9261 16.0414C11.0456 18.1566 12.214 20.9901 12.2196 23.9915V24H16.824C16.8198 19.6341 15.0446 15.6802 12.1759 12.81C9.3071 9.93979 5.3532 8.16322 0.988662 8.16181ZM1.91999 23.0405V23.039C1.91858 23.039 1.91858 23.039 1.91858 23.039L1.91999 23.0405ZM6.42422 18.5517C7.00701 19.1331 7.36119 19.9205 7.3626 20.7982C7.36119 21.6731 7.00701 22.4619 6.42704 23.039L6.42422 23.0405C5.84567 23.6176 5.05263 23.9718 4.1721 23.9718C3.29017 23.9718 2.49995 23.6162 1.91999 23.039C1.34285 22.4619 0.98584 21.6745 0.98584 20.7982C0.98584 19.9205 1.34285 19.1317 1.91999 18.5517C2.49995 17.9746 3.29017 17.6176 4.1721 17.6176C5.05263 17.6176 5.84567 17.9746 6.42422 18.5517Z"
      />
    </svg>
  )
}

IconRSS.propTypes = {
  elementClass: PropTypes.string,
}

export default IconRSS
