import React from 'react'
import BoPChart from '../BoPChart'
import useData from '../../hooks/useData'
import PropTypes from 'prop-types'
import PresidentElectionTable from '../PresidentElectionTable'
import PresidentNational from '../PresidentNational'
import Loading from '../Loading'
import Error from '../Error'

const NationalResults = ({ url, interval }) => {
  const data = useData(url, interval)

  if (data === undefined) {
    return <Loading />
  } else if (typeof data === 'string') {
    return <Error err={data.toString()} />
  }
  return (
    <div data-testid="national-data">
      {data.president.length > 0 && (
        <>
          <PresidentNational candidates={data.president} />
          <p>&nbsp;</p>
          <PresidentElectionTable candidates={data.president} />
          <p>&nbsp;</p>
        </>
      )}
      <BoPChart {...data.bopUsSenate} label="US Senate Balance of Power" />
      <p>&nbsp;</p>
      <BoPChart {...data.bopUsHouse} label="US House Balance of Power" />
      <p>&nbsp;</p>
      <BoPChart {...data.bopGovernor} label="Governor Balance of Power" />
    </div>
  )
}

NationalResults.propTypes = {
  url: PropTypes.string.isRequired,
  interval: PropTypes.number,
}

export default NationalResults
