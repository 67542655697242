// @ TODO
import logo from '../../assets/svg/logo-election2020.svg'

const nav = [
  {
    title: 'Presidential Results',
    href: 'president',
    icon: 'whitehouse',
  },
  {
    title: 'National Results',
    href: 'national',
    icon: 'us',
  },
  {
    title: 'U.S. Senate',
    href: 'us/senate',
    icon: 'mn',
  },
  {
    title: 'U.S. House',
    href: 'us/house',
    icon: 'mn-counties',
  },
  {
    title: 'Minnesota Senate',
    href: 'mn/senate',
    icon: 'ballot-box',
  },
  {
    title: 'Minnesota House',
    href: 'mn/house',
    icon: 'mn-house',
  },
  {
    title: 'Judicial',
    href: 'judicial',
    icon: 'gavel',
  },
  {
    title: 'Local Races by County',
    href: 'county',
    icon: 'mn-counties',
  },
  {
    title: 'Live Coverage',
    href: 'https://live.mprnews.org/Event/Election_2020_Minnesota_and_around_the_region',
    icon: 'live',
  },
]

const overview = [
  {
    title: 'President',
    href: 'president',
    buttonText: 'Map & Details',
    type: 'PresidentialNational',
  },
  {
    type: 'PresidentialMinn',
  },
  {
    label: 'attorneyGeneral',
    type: 'Race',
  },

  {
    title: 'U.S. Senate and House',
    href: 'national',
    buttonText: 'More Results',
    type: 'BalanceOfPower',
    area: 'U.S.',
    bop: 'bopUs',
    label: 'U.S. Senate',
    headerText:
      'In the Senate, 24 Democrats, nine Republicans and two Independents are up for election. All 435 seats in the House are up for election.',
  },
  {
    title: 'U.S. Senate',
    href: 'us/senate',
    buttonText: 'Map & Details',
    label: 'usSenate',
    type: 'Race',
  },
  {
    title: 'U.S. House in Minnesota',
    label: 'usHouse',
    href: 'us/house',
    buttonText: 'Map & Details',
    type: 'Race',
  },
  {
    title: 'Minnesota Legislature',
    type: 'BalanceOfPower',
    area: 'MN',
    bop: 'bopMn',
    headerText: 'All seats in the House and Senate are up for reelection.',
  },
  {
    title: 'Judicial Races',
    href: 'judicial',
    buttonText: 'See All Races',
    bodyText:
      "Results in the state's District Courts, Court of Appeals and Supreme Court. Judges run in nonpartisan elections to serve six-year terms.",
  },
  {
    title: 'Local Races',
    href: 'county',
    buttonText: 'View Counties',
    bodyText:
      'Results in mayoral races, school boards, ballot measures and more.',
  },
]

const national = [
  {
    title: 'U.S. Senate',
    href: 'us/senate',
    buttonText: 'More Results',
    type: 'BalanceOfPower',
    area: 'U.S.',
    bop: 'bopUsSenate',
    label: '',
    headerText:
      'Twenty-four Democrats, nine Republicans and two Independents are up for election.',
  },
  {
    title: 'U.S. House',
    href: 'us/house',
    buttonText: 'More Results',
    type: 'BalanceOfPower',
    area: 'U.S.',
    bop: 'bopUsHouse',
    label: '',
    headerText: 'All 435 seats in the House are up for election.',
  },
  {
    title: 'U.S. Governors',
    // href: 'interactive/2020-governor',
    // buttonText: 'More Results',
    type: 'BalanceOfPower',
    area: 'U.S.',
    bop: 'bopGovernor',
    label: '',
    headerText: 'Thirty-six governors are up for election.',
  },
]

const priorityCandidates = ['Joe Biden', 'Donald Trump']

const electionConfig2020Presidential = {
  ap: false,
  slug: 'electionConfig2020Presidential',
  date: '2020-11-03',
  nav: nav,
  year: '2020',
  overview: overview,
  national: national,
  logo: logo,
  priorityPresidentialCandidates: priorityCandidates,
}

export default electionConfig2020Presidential
