export default {
  appsTitle: 'MPR News mobile apps',
  appsImg: '/app-news.jpg',
  apps: [
    {
      image: '/data/imgs/footer/badge-app-store.svg',
      href: 'https://itunes.apple.com/us/app/mpr-news/id400286612',
      label: 'Download MPR News on the App Store',
    },
    {
      image: '/data/imgs/footer/badge-google-play.svg',
      href: 'https://play.google.com/store/apps/details?id=org.mpr.mprnews',
      label: 'Get MPR News on Google Play',
    },
  ],
  contact: [
    {
      icon: 'facebook',
      label: 'Facebook',
      href: 'https://www.facebook.com/MPRnews',
    },
    {
      icon: 'instagram',
      label: 'Instagram',
      href: 'https://www.instagram.com/mprnews/',
    },
    {
      icon: 'linkedin',
      label: 'LinkedIn',
      href: 'https://www.linkedin.com/company/mpr-news/',
    },
    {
      icon: 'reddit',
      label: 'Reddit',
      href: 'https://www.reddit.com/user/MPRnews/',
    },
    {
      icon: 'tiktok',
      label: 'TikTok',
      href: 'https://www.tiktok.com/@mprnews',
    },
    {
      icon: 'rss',
      label: 'RSS',
      href: 'https://www.mpr.org/listen/rss',
    },
  ],
  contactTitle: 'Connect with us',
  listenHref: '/listen',
  listenText: 'Listen Live',
  nav: [
    {
      label: 'About MPR News',
      href: '/about',
    },
    { label: 'Careers', href: 'https://apmgcareers.org' },
    { label: 'Contact Us', href: 'https://www.mpr.org/contact' },
    { label: 'Events', href: 'https://www.mprevents.org' },
    {
      label: 'Frequently Asked Questions',
      href: '/faq',
    },
    {
      label: 'Fundraising Credentials',
      href: 'https://www.mpr.org/support/fundraising-credentials',
    },
    {
      label: 'More from MPR',
      href: 'https://www.mpr.org',
    },
    {
      label: 'Newsletters',
      href: '/newsletters',
    },
    {
      label: 'Outages',
      href: 'https://www.mpr.org/station-outages',
    },
    {
      label: 'Press',
      href: 'https://www.mpr.org/press',
    },
    { label: 'Privacy', href: 'https://www.americanpublicmedia.org/privacy' },
    {
      label: 'Shop',
      href: 'https://www.publicmediamarket.org/collections/mpr-news',
    },
    { label: 'Staff Directory', href: '/department/mpr-newsroom' },
    {
      label: 'Stations',
      href: 'https://www.mpr.org/listen/stations',
    },
    { label: 'Terms', href: 'https://www.americanpublicmedia.org/terms/' },
    { label: 'Why Trust Us', href: '/ethics' },
  ],
  podcastTitle: 'MPR News Podcasts',
  podcastText:
    'Check out MPR News podcasts. We have daily updates from Minnesota Today and Minnesota Now with Cathy Wurzer, in-depth conversations about life in our state with MPR News with Angela Davis, plus Climate Cast and more.',
  podcastLinkHref: '/podcasts',
  podcastLinkLabel: 'All Podcasts',
  programmingLinks: [
    { label: 'Program Schedule', href: '/schedule', hrefType: 'schedule' },
    {
      label: 'Station Directory',
      href: 'https://www.mpr.org/listen/stations',
      hrefType: 'externalLink',
    },
    {
      label: 'Audio Help',
      href: 'https://www.mpr.org/about/faqs',
      hrefType: 'externalLink',
    },
  ],
  programmingTitle: 'Where to Listen',
  subscribeTitle: 'Subscribe to email newsletters',
}
