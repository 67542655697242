// import { NAV_RACES } from './config';
// http://stackoverflow.com/questions/15819904/how-can-i-detect-ie10-from-js-when-browser-mode-is-ie9
// export function whichInternetExplorer() {
//   var ret, isTheBrowser, actualVersion, jscriptMap, jscriptVersion;

//   isTheBrowser = false;
//   jscriptMap = {
//     '5.5': '5.5',
//     '5.6': '6',
//     '5.7': '7',
//     '5.8': '8',
//     '9': '9',
//     '10': '10'
//   };
//   jscriptVersion = new Function('/*@cc_on return @_jscript_version; @*/')();

//   if (jscriptVersion !== undefined) {
//     isTheBrowser = true;
//     actualVersion = jscriptMap[jscriptVersion];
//   }

//   ret = {
//     isTheBrowser: isTheBrowser,
//     actualVersion: actualVersion
//   };

//   return ret;
// }

export function round10(value, exp) {
  // Decimal round
  return decimalAdjust('round', value, exp)
}

export function floor10(value, exp) {
  // Decimal floor
  return decimalAdjust('floor', value, exp)
}

export function ceil10(value, exp) {
  // Decimal ceil
  return decimalAdjust('ceil', value, exp)
}

export function humanNumbers(int) {
  return int.toLocaleString('en-US')
}

export function slugify(text) {
  // https://gist.github.com/mathewbyrne/1280286
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export function humanReadableKey(str) {
  return this.toTitleCase(str.toString().replace('_', ' '))
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

// Checks for 'http://', 'https://', or '//' at the beginning of a string
export function isInternalLink(href) {
  const reg = /^(http:\/\/|https:\/\/|\/\/)/
  return reg.test(href)
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round#Decimal_rounding
/**
 * Decimal adjustment of a number.
 *
 * @param {String}  type  The type of adjustment.
 * @param {Number}  value The number.
 * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
 * @returns {Number} The adjusted value.
 */
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value)
  }
  value = +value
  exp = +exp
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN
  }
  // Shift
  value = value.toString().split('e')
  value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)))
  // Shift back
  value = value.toString().split('e')
  return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp))
}

// Returns only date slug
export function findDateSlug() {
  var urlParts = window.location.pathname.split('/')
  urlParts.shift()
  return urlParts[0]
}

// Return url pathname endpoint
// export function pathNameEndPoint(url) {
//   var pathnameUrl = url
//     .replace(/https?:\/\/[A-Za-z\.\-:0-9]+\//, '')
//     .split('/');
//   var endPoint = pathnameUrl[1];
//   if (pathnameUrl.length > 2) {
//     if (pathnameUrl[2] === 'county') {
//       return pathnameUrl[2];
//     }
//     return pathnameUrl.splice(2, 2).join('/');
//   } else {
//     return endPoint;
//   }
// }

// Maps, pathname endpoint to config Array values, and if pathname endpoint matches
// config href, then return it's title
// export function returnTitles(data) {
//   var slugDate = findDateSlug();
//   var jsonSlug = data;
//   var filterMethod = NAV_RACES[slugDate].filter(function(slug) {
//     return slug.href === jsonSlug;
//   });
//   if (filterMethod.length < 1) {
//     return '';
//   }
//   return filterMethod[0].title;
// }

// Add default title, and change title on navigation
export function PageTitle(data) {
  var title = data
  var pageTitleDefault = 'Live Election Results From MPR'
  if (title === '') {
    return pageTitleDefault
  } else {
    return `${title} | ${pageTitleDefault}`
  }
}
// Execute title change on navigation
// export function onChangePageTitle() {
//   var title = returnTitles(pathNameEndPoint(window.location.pathname));
//   document.title = PageTitle(title);
// }

// Return a normalized race level
export function raceLevel(race) {
  // Check the weird ones first
  if (race.name === 'Auditor') return 'mnAuditor'
  if (RegExp('^(Appeals Court)').test(race.name)) return 'appealsCourt'

  // These ones have an ID from the AP
  switch (race.apOfficeId) {
    case 'P':
      return 'president'
    case 'G':
      return 'governor'
    case 'A':
      return 'attorneyGeneral'
    case 'R':
      return 'secretaryOfState'
    case 'S':
      return 'usSenate'
    case 'H':
      return 'usHouse'
    case 'Z':
      return 'mnSenate'
    case 'Y':
      return 'mnHouse'
    case 'J':
      return 'mnSupremeCourt'
    default:
      return null
  }
}

export function isEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

export const useDefaultImg = (event) => {
  // Fallback if the image file for a candidate does not exist
  event.target.src = DEFAULT_CANDIDATE_IMAGE
}

export const DEFAULT_CANDIDATE_IMAGE = '/data/imgs/candidates/default.jpg'
export const MN_HOUSE_DISTRICT_PATTERN =
  /^(?!(6[89])[AB]$)^[123456789]{1}(\d{1})?[AB]$/ // Accepts 1A through 67B
export const MN_SENATE_DISTRICT_PATTERN = /^(?!6[89]$)^[123456789]{1}(\d{1})?$/ // Accepts 1 through 67
export const US_HOUSE_DISTRICT_PATTERN = /(^[12345678]{1}$)/ // Accepts 1 through 8
export const MN_COUNTY_CODE_PATTERN = /27[0,1]{1}\d{1}[1,3,5,7,9]{1}/ // accepts 27001 and up, odds only

/**
 * Given an array of candidates, will return the candidate with the most votes
 * @param {array} candidates
 */
export function getWinningCandidate(candidates) {
  if (!candidates?.length) return undefined
  // from highest vote count to lowest
  const sortedCandidates = [...candidates].sort(
    (a, b) => b.votecount - a.votecount
  )

  return { ...sortedCandidates[0] }
}
