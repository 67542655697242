import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import CountyResults from './CountyResults'
import { API_ENDPOINT } from '../../config'
import ElectionContext from '../../context/ElectionContext'

const County = ({ countyCode, start }) => {
  const context = useContext(ElectionContext)

  return (
    <div className="County">
      <CountyResults
        interval={30000}
        countyCode={countyCode}
        url={`${API_ENDPOINT}/${context.date}/counties/${countyCode}`}
        start={start}
      />
    </div>
  )
}

County.propTypes = {
  countyCode: PropTypes.string,
  start: PropTypes.string,
}

export default County
