import React from 'react'
import PropTypes from 'prop-types'

const FooterApps = (props) => {
  return (
    <div className="footer-apps">
      <h3 className="hdg-5">Get the app</h3>
      <ul className="footer-app-list">
        {props.links.map((link) => {
          return (
            <li key={link.href} className="footer-app-link">
              <a href={link.href}>
                {link.image ? (
                  <img
                    src={link.image}
                    alt={link.label}
                    className="footer-app-link-img"
                  />
                ) : (
                  <>{link.label}</>
                )}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

FooterApps.propTypes = {
  title: PropTypes.string,
  imageSrc: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      image: PropTypes.string,
      label: PropTypes.string,
    })
  ),
}

export default FooterApps
