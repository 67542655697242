import React, { useContext, useState, useEffect } from 'react'
import Loading from '../Loading'
import RaceFull from '../RaceFull'
import { raceLevel } from '../../util'
import PropTypes from 'prop-types'
import useData from '../../hooks/useData'
import Error from '../Error'
import maps from '../../config/maps'
import { useHistory } from 'react-router-dom'
import ElectionContext from '../../context/ElectionContext'
import ErrorBoundary from '../Error/ErrorBoundary'

const CountyResults = ({ url, countyCode, interval, start }) => {
  const history = useHistory()
  const context = useContext(ElectionContext)
  const data = useData(url, interval)
  const map = maps.president
  const [selectedRace, setSelectedRace] = useState(null)
  const mapData = useData(map.topoJson, false)
    ?.objects?.mnCounties?.geometries?.map((item) => item.properties)
    ?.sort((a, b) => (a[map.properName] > b[map.properName] ? 1 : -1))

  useEffect(() => {
    if (start === 'ussenate') {
      setSelectedRace(data?.races[1])
    }
  }, [data])

  if (data === undefined) {
    return <Loading />
  } else if (typeof data === 'string') {
    return <Error err={data} />
  }

  const countyString = `, ${data.countyName} County precincts`
  const buildRaceNodes = (raceList = data.races) => {
    return raceList.map(function (race, index) {
      return (
        <RaceFull
          {...race}
          level={raceLevel(race)}
          addLabel={raceLevel(race) && countyString}
          key={
            race.name ? `${race.name}-${index}` : `${race.district}-${index}`
          }
        />
      )
    })
  }

  return (
    <div className="grid_col">
      <div className="section_header">
        <h2 className="hdg hdg-2 hdg-em">{data.countyName} County Results</h2>
      </div>
      <select
        onChange={(event) => {
          history.push(event.target.value)
          setSelectedRace(null)
        }}
        onBlur={(event) => history.push(event.target.value)}
        defaultValue={`/${context.date}/county/${countyCode}`}
      >
        {mapData?.map((county, idx) => (
          <option
            key={idx}
            value={`/${context.date}/county/${county[map.keyAttribute]}`}
          >
            {county[map.properName]}
          </option>
        ))}
      </select>
      {/* <div className="section section-lg">
        <CountyMap
          width={450}
          height={350}
          center={[-94, 46.5]}
          projection={projection}
          topoJson={map.topoJson}
          keyAttribute={map.keyAttribute}
          properName={map.properName}
          highlightZone={countyCode}
        />
      </div> */}
      <h3 className="hdg hdg-3 bopChart_title">
        Select a race in {data.countyName} county
      </h3>
      <select
        onChange={(event) => {
          setSelectedRace(data.races[event.target.value])
        }}
        defaultValue={start === 'ussenate' ? 1 : ''}
      >
        <option>Choose a race</option>
        {data.races.map((race, idx) => {
          return (
            <option key={idx} value={idx}>
              {race.name}
            </option>
          )
        })}
      </select>
      {selectedRace && (
        <ErrorBoundary>
          <RaceFull
            {...selectedRace}
            level={raceLevel(selectedRace)}
            addLabel={raceLevel(selectedRace) && countyString}
            key={selectedRace.name.toLowerCase()}
          />
        </ErrorBoundary>
      )}
      {!selectedRace &&
        data.races.map((race, idx) => {
          return (
            <div key={idx}>
              <ErrorBoundary>
                <RaceFull
                  {...race}
                  level={raceLevel(race)}
                  addLabel={raceLevel(race) && countyString}
                  key={race.name.toLowerCase()}
                />
              </ErrorBoundary>
            </div>
          )
        })}
    </div>
  )
}

CountyResults.propTypes = {
  url: PropTypes.string.isRequired,
  countyCode: PropTypes.string,
  interval: PropTypes.number,
  start: PropTypes.string,
}

export default CountyResults
