import { useState, useEffect, useContext } from 'react'
import ElectionContext from '../context/ElectionContext'
import unfetch from 'unfetch'

const useData = (url, interval = 60000) => {
  const context = useContext(ElectionContext)
  const [results, setResults] = useState(undefined)

  useEffect(() => {
    let intervalId
    if (context.ap !== true) {
      const loadData = async () => {
        try {
          const res = await unfetch(url)
          const data = await res.json()
          setResults(data)
        } catch (err) {
          setResults('Polls close at 8 pm on November 5th.')
        }
      }

      loadData()

      if (interval) {
        intervalId = setInterval(() => loadData(), interval)
      }

      // remove data subscription when component unmounts
      return () => clearInterval(intervalId)
    }
  }, [interval, url, context])

  return results
}

export default useData
