import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const IconReddit = (props) => {
  const classes = classNames({
    icon: true,
    'icon-reddit': true,
    [props.elementClass]: props.elementClass,
  })

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`icon icon-mail ${
        props.elementClass ? props.elementClass : ''
      }`}
    >
      <title>Reddit</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5,16.1c-0.5,0.5-1.7,0.7-2.5,0.7s-2-0.2-2.5-0.7c-0.1-0.1-0.3-0.1-0.5,0c-0.1,0.1-0.1,0.3,0,0.5
	c0.9,0.9,2.5,0.9,3,0.9c0.5,0,2.1-0.1,3-0.9c0.1-0.1,0.1-0.3,0-0.5C15.8,16,15.6,16,15.5,16.1z M11.5,13.3c0-0.7-0.6-1.2-1.2-1.2
	C9.5,12,9,12.6,9,13.3c0,0.7,0.6,1.2,1.3,1.2C10.9,14.5,11.5,13.9,11.5,13.3z M15.7,12c-0.7,0-1.3,0.6-1.3,1.3
	c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.2-0.6,1.2-1.3C17,12.6,16.4,12,15.7,12z M13,0C6.4,0,1,5.4,1,12s5.4,12,12,12c6.6,0,12-5.4,12-12
	S19.6,0,13,0z M19.9,13.6c0,0.2,0,0.3,0,0.5c0,2.7-3.1,4.9-7,4.9c-3.9,0-7-2.2-7-4.9c0-0.2,0-0.4,0-0.5c-0.6-0.3-1-0.9-1-1.6
	c0-1,0.8-1.8,1.7-1.8c0.5,0,0.9,0.2,1.2,0.5c1.2-0.9,2.9-1.4,4.7-1.5l0.9-4.2c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.2-0.1,0.2,0l2.9,0.6
	C17.1,5,17.5,4.8,18,4.8c0.7,0,1.2,0.6,1.2,1.3S18.7,7.3,18,7.3c-0.7,0-1.2-0.5-1.2-1.2l-2.6-0.6l-0.8,3.8c1.8,0.1,3.5,0.6,4.7,1.5
	c0.3-0.3,0.7-0.5,1.2-0.5c1,0,1.7,0.8,1.7,1.8C21,12.7,20.6,13.3,19.9,13.6z"
      />
    </svg>
  )
}

IconReddit.propTypes = {
  elementClass: PropTypes.string,
}

export default IconReddit
