import React, { Component } from 'react'

class IconLocationCheck extends Component {
  render() {
    return (
      <symbol id="icon-location-check" viewBox="0 -960 960 960">
        <path d="m462.7-418.83 165.47-166.04-62.13-61.56L462.7-543.09l-51-51-62.13 62.13L462.7-418.83ZM480-207.7q114.61-98.74 175.78-182.6 61.18-83.87 61.18-162.83 0-98.17-67.96-167.5t-169-69.33q-101.04 0-169 69.33t-67.96 167.5q0 78.96 61.18 162.33Q365.39-307.44 480-207.7Zm0 152.61Q301.17-199.78 214.63-317.72q-86.54-117.93-86.54-235.41 0-141.48 99.17-246.63T480-904.91q152.57 0 252.24 105.15 99.67 105.15 99.67 246.63 0 117.48-86.04 234.91Q659.83-200.78 480-55.09ZM480-552Z" />{' '}
      </symbol>
    )
  }
}

export default IconLocationCheck
