import React from 'react'
import PropTypes from 'prop-types'
import IconFacebook from './IconFacebook'
import IconMail from './IconMail'
import IconTwitter from './IconTwitter'
import IconLinkedin from './IconLinkedin'
import IconInstagram from './IconInstagram'
import IconReddit from './IconReddit'
import IconTikTok from './IconTikTok'
import IconRSS from './IconRSS'

const icons = {
  facebook: IconFacebook,
  linkedin: IconLinkedin,
  mail: IconMail,
  reddit: IconReddit,
  rss: IconRSS,
  tiktok: IconTikTok,
  twitter: IconTwitter,
  instagram: IconInstagram,
}

const Icon = (props) => {
  const { name, color, ...rest } = props

  // Check if the icon is in the list; if not, render the empty icon
  const Element = icons[name] ? icons[name] : icons.empty

  return <Element color={color} {...rest} />
}

Icon.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
}

export default Icon
