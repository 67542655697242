export const DEPLOY_TIME = '1478641040'

export const DATE_WHITELIST = [
  '2024-11-05',
  '2022-11-08',
  '2020-11-03',
  '2020-08-11',
  '2020-03-03',
  '2018-11-06',
  '2016-11-08',
]

export const DATE_COMPARISON = {
  '2022-11-08': '2020-11-03',
  '2020-11-03': '2018-11-06',
  '2018-11-06': '2016-11-08',
}

// No trailing slash
export const API_ENDPOINT = findEndpoint()

// TODO: Can we do this in .env files instead? Tackle during "Set up Deploy" issue.
function findEndpoint() {
  var origin
  switch (window.location.origin) {
    case 'http://localhost:3000':
    case 'http://localhost:3001':
    case 'http://localhost:5000':
      origin = 'https://electionsapi.publicradio.org'
      break
    case 'https://elections-dev.mpr.org':
      origin = 'https://electionsapi.publicradio.org'
      break
    case 'https://elections-stage.mpr.org':
      origin = 'https://electionsapi.publicradio.org'
      break
    case 'https://elections.mpr.org':
      origin = 'https://electionsapi.publicradio.org'
      break
    case 'https://origin-elections.mpr.org':
      origin = 'https://electionsapi.publicradio.org'
      break
    default:
      origin = 'https://electionsapi.publicradio.org'
      break
  }
  return origin
}

export const TOTAL_ELECTORS = 538

export const PARTY_COLORS = {
  dem: '#4298b5',
  gop: '#eb5c40',
  ind: '#f9a909',
  lib: '#fc6d80',
  grn: '#2eba80',
  grp: '#0a3c20',
  lmn: '#177840',
  cst: '#978054',
  adp: '#12515d',
  swp: '#6b0000',
  yes: '#2eba80',
  no: '#eb5c40',
  oth: '#887777',
  wi: '#999999',
}

// TODO: make this less lazy someday (but I can't check the file system without Node, and this filter specifically proved difficult to set up)
export const THOSE_WHOMST_HAVE_PICS = [
  1036, 1445, 6588, 8639, 18446, 32324, 51516, 51526, 51545, 57725, 59911,
  61562, 61839, 62244, 62589, 63133, 63672, 63747, 64542, 64548, 64984, 67933,
  67934, 67935, 67936, 67941, 67944, 67946, 67948, 67952, 67963, 67972,
]

export const PARTY_CSS_CLASSES = {
  Dem: 'dem',
  GOP: 'gop',
  LMN: 'lmn',
  GRP: 'grp',
  Inp: 'ind',
  Grn: 'grn',
  DCG: 'grn',
  Lib: 'lib',
  ADP: 'adp',
  CST: 'cst',
  SWP: 'swp',
  Yes: 'yes',
  No: 'no',
  Oth: 'oth',
}

export const PARTY_FULL_NAMES = {
  Dem: 'Democratic-Farmer-Labor',
  GOP: 'Republican',
  LMN: 'Legal Marijuana Now',
  GRP: 'Grassroots-Legalize Cannabis',
  Inp: 'Independence',
  Grn: 'Green Party',
  Lib: 'Libertarian Party',
  ADP: 'American Delta Party',
  CST: 'Constitution Party',
  SWP: 'Socialist Workers Party',
  Una: 'Unaffiliated',
  NP: 'Nonpartisan',
  Yes: ' ',
  No: ' ',
  Oth: ' ',
  WI: 'Write-in',
}
