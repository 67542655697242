import React, { useState, useEffect } from 'react'
import config from './footerConfig'
import FooterContact from './FooterContact'
import FooterLinks from './FooterLinks'
import FooterApps from './FooterApps'

function useMounted() {
  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])
  return mounted
}

const MprFooter = () => {
  const mounted = useMounted()
  return (
    <>
      {mounted && (
        <footer className="footer wrapper">
          <div className="footer-contents container">
            <div className="footer-logo-donate">
              <h3 className="footer-logo">
                <img
                  width={140}
                  height={28}
                  src="/data/imgs/footer/mpr-logo-footer.svg"
                  alt="Minnesota Public Radio"
                  loading="lazy"
                />
              </h3>
              <div className="footer-donate">
                <a
                  href="https://support.mpr.org/news-web"
                  className="btn btn-primary btn-slim small-gap"
                  target="_blank"
                  aria-label="Donate Now - Link opens in a new tab"
                >
                  <span className="type-lg icon-heart">♡</span>Donate Now
                </a>
              </div>
            </div>
            <FooterLinks links={config.nav} />
            <FooterApps links={config.apps} />
            <FooterContact links={config.contact} />
            <div className="footer-copyright type-sm">
              &copy; {new Date().getFullYear()} Minnesota Public Radio. All
              rights reserved.
            </div>
          </div>
        </footer>
      )}
    </>
  )
}

export default MprFooter
